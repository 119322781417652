import get from 'lodash/get';
import merge from 'lodash/merge';
import cloneDeep from 'helpers/cloneDeep';
import Templates from '../Templates';
import store from '../../../store';

/**
 * TemplateHelpers
 * This are helpers used for templates
 */
export default class TemplateHelpers {
    // Check if a backgroundModel exist in the assetData. If the value 'isBackgroundImage' is also true, return the value of the backgroundModel
    static findBackgroundModelInAssetData(assetData) {
        const checkObject = (obj, key) => {
            if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
                return false;
            }
            // If the object has a backgroundModel and isBackgroundImage is set to true, return the model
            else if (obj.hasOwnProperty(key) && obj.isBackgroundImage) {
                return obj[key];
            } else {
                for (const k in obj) {
                    const result = checkObject(obj[k], key);
                    if (result) {
                        return result;
                    }
                }
            }

            return false;
        };

        return checkObject(assetData, 'backgroundModel');
    }

    /**
     * Find image format
     * This extracts the dimension (e.g. 1x1) from the formatData object
     * @param {*} formatData
     */
    static getFormatDimension(formatData = {}, width = 0, height = 0) {
        if (formatData.format === 'main4x5' || formatData.format === 'newsfeed4x5') {
            return '4x5';
        }
        if (formatData.format === 'main1x1' || formatData.format === 'square') {
            return '1x1';
        }
        if (formatData.format === 'main16x9' || formatData.format === 'horizontal' || formatData.format === 'landscape') {
            return '16x9';
        }
        if (formatData.format === 'main9x16' || formatData.format === 'vertical' || formatData.format === 'stories_ad' || formatData.format === 'portrait') {
            return '9x16';
        }
        if (formatData.width === 1920 && formatData.height === 1080) {
            return '16x9';
        }
        if (formatData.width === 1080 && formatData.height === 1080) {
            return '1x1';
        }
        if (formatData.width === 1080 && formatData.height === 1920) {
            return '9x16';
        }
        if (width === 1920 && height === 1080) {
            return '16x9';
        }
        if (width === 1080 && height === 1080) {
            return '1x1';
        }
        if (width === 1080 && height === 1350) {
            return '4x5';
        }
        if (width === 1080 && height === 1920) {
            return '9x16';
        }
        if (formatData.width === formatData.height) {
            return '1x1';
        }
        return '16x9';
    }

    /**
     * Check if template is a template designer
     *
     * @returns boolean if template is a template designer type
     */
    static isTemplateDesignerType(type) {
        const templateTypes = ['dynamicImageDesigned', 'dynamicVideoDesigned', 'displayAdDesigned', 'dynamicPDFDesigned'];

        return templateTypes.includes(type);
    }

    /**
     * Get version of the template
     * @returns string version number
     */
    static getTemplateDesignerVersion(type, templateIdentifier) {
        const template = Templates.get(type, templateIdentifier);

        if (!template) return '1';
        return template.version ? template.version : '1';
    }

    /**
     * Get version of the template
     * @returns string version number
     */
    static getTemplateTitle(type, templateIdentifier) {
        const template = Templates.get(type, templateIdentifier);

        if (!template) return;
        return template.title;
    }

    /**
     * Title compare function
     */
    static compareTitle = (a, b) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    };

    /**
     * Sort array of objects based on another array
     * @param {array} templates
     * @param {array} order
     */
    static compareOrderedTemplates = (templates, order) => {
        let source = [...templates];
        const results = [];

        order.forEach((identifier) => {
            const index = templates.findIndex((t) => t.identifier === identifier);
            if (index > -1) {
                results.push(templates[index]);
                // source.splice(index, 1);
                source = source.filter((s) => s.identifier !== identifier);
            }
        });

        return [...results, ...source];
    };

    /**
     * Filter feature
     * Takes the current items and filter by searchterm or category
     * @param {Array} options //Options to filter on and array of templates
     * @param {Array} orderedTemplates //All templates in order. To also get the result in the same order
     * @param {String} searchCategory //Category being searched on e.g. 'twitter'
     * @param {String} searchTerm //Template name being searched on e.g. 'end of year social image'
     * @returns Filterd and ordered templates
     */
    static filter = (options = [], orderedTemplates = [], searchCategory, searchTerm) => {
        let newOptions = [...options];

        if (searchCategory.length > 0) {
            newOptions = newOptions.filter((option) => {
                return option.category && option.category.toLowerCase() === searchCategory.toLowerCase();
            });
        }

        // We have a searchterm, start searching
        if (searchTerm.length > 0) {
            const term = searchTerm
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            newOptions = newOptions.filter((option) => {
                if (!option.title) {
                    option.title = '';
                }
                const searchTitle = option.title
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                if (!option.description) {
                    option.description = '';
                }
                const searchDesc = option.description
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                return searchTitle.indexOf(term) >= 0 || searchDesc.indexOf(term) >= 0;
            });
        }

        // Sort the results by title.
        newOptions.sort(this.compareTitle);

        if (orderedTemplates && orderedTemplates.length > 0) {
            newOptions = this.compareOrderedTemplates(newOptions, orderedTemplates); //I dont see this function
        }

        return newOptions;
    };

    /* Remove format data
     * This strips data in the export that we do not need anymore
     * @param {*} template
     * @param {*} format
     */
    static removeTemplateFormatData(template, format, dataSet) {
        // Filter the formats
        template.formats = template.formats.filter((item) => item.key === format);

        // Strip frameTypes for single frame item
        if (dataSet.type && !dataSet.frames && dataSet.type !== 'none') {
            template.frames = {
                ...(template.frames.base && { base: template.frames.base }),
                [dataSet.type]: template.frames[dataSet.type]
            };
        }

        // Strip frames for multi frame item
        if (dataSet.frames) {
            const newFrames = {
                ...(template.frames.base && { base: template.frames.base })
            };
            Object.keys(dataSet.frames).forEach((frameKey) => {
                const frame = dataSet.frames[frameKey];
                if (frame.type) {
                    newFrames[frame.type] = template.frames[frame.type];
                }
            });
            template.frames = newFrames;
        }

        // Strip format data
        Object.keys(template.frames).forEach((key) => {
            const frameType = template.frames[key];

            if (!frameType) return;

            if (frameType.style) {
                frameType.style = { [format]: frameType.style[format] };
            }
            if (frameType.hover) {
                frameType.hover = { [format]: frameType.hover[format] };
            }

            // Loop through layers and filter formats
            if (frameType.layers) {
                Object.keys(frameType.layers).forEach((layerKey) => {
                    Object.keys(frameType.layers[layerKey].formats).forEach((key) => {
                        if (key !== format) {
                            delete frameType.layers[layerKey].formats[key];
                        }
                    });
                });
            }
        });
    }

    /**
     * Remove Gallery from data
     */
    static removeGallery(data, language) {
        if (typeof data === 'object' && data !== null) {
            for (const i in data) {
                if (data[i] && typeof data[i] === 'object' && data[i] !== null && data[i].assetGalleryInput) {
                    const newData = { url: data[i].url };

                    if (language && data[i][language] && data[i][language].url) {
                        newData.url = data[i][language].url;
                    }

                    if (data[i].transforms) {
                        newData.transforms = data[i].transforms;
                    }
                    data[i] = newData;
                } else if (i === 'gallery' || i === 'originalImage' || i === 'originalRecord' || i === 'crop') {
                    delete data[i];
                } else {
                    TemplateHelpers.removeGallery(data[i], language);
                }
            }
        }
    }

    /**
     * Remove feed data from the feed
     * @param {*} data
     */
    static removeFeedData(data) {
        if (data && data.feedItem && data.feedItem.originalRecord) {
            delete data.feedItem.originalRecord;
        }
    }

    /**
     * Parses interface setup data to the data that is needed to start a render.
     * @param {object} sourceData - Initial source data.
     * @param {object} variables - Data that is needed to parse the data.
     * @returns {object} New source data with data for the render.
     */
    static parseTdAfterEffects = (sourceData, variables = {}, language, preview = false) => {
        const comp = (() => {
            if (variables.format) {
                return variables.template.compositions.find((composition) => composition.format && composition.format === variables.format);
            }

            if (variables.template.formats && variables.template.formats.length) {
                return variables.template.formats[0];
            }
        })();

        if (!comp) {
            throw 'No comp found';
        }

        sourceData = {
            template: {
                identifier: variables.template.identifier,
                url: variables.template.downloadUrl
            },
            comp: comp.originalName || comp.key,
            stillTime: 1,
            priority: preview ? 3 : 1,
            tdtemplatedata: TemplateHelpers.getAfterEffectsRenderJob(
                variables.template.layers,
                variables.template.interfaceSetup,
                variables.blockData,
                language || store.getState().editor.language
            )
        };

        return sourceData;
    };

    /**
     * Parses interface setup data to the data that is needed to start a render.
     * @param {object} sourceData - Initial source data.
     * @param {object} variables - Data that is needed to parse the data.
     * @returns {object} New source data with data for the render.
     */
    static parseTdInDesign = (sourceData, variables = {}, language, priority = false) => {
        const page = (() => {
            if (variables.format) {
                return variables.template.pages.find((page) => page.format && page.format === variables.format);
            }

            if (variables.template.formats && variables.template.formats.length) {
                return variables.template.formats[0];
            }
        })();

        if (!page) {
            throw 'No page found';
        }

        sourceData = {
            template: {
                identifier: variables.template.identifier,
                url: variables.template.downloadUrl
            },
            page: page || 0,
            convertAllTextToOutlines: false,
            parameters: TemplateHelpers.getInDesignRenderJob(
                variables.template.layers,
                variables.template.interfaceSetup,
                variables.blockData,
                language || store.getState().editor.language
            ),
            priority: priority ? 3 : 1,
            preview: variables.preview || false
        };

        if (variables.blockData.jobOptions) {
            sourceData.jobOptions = variables.blockData.jobOptions;
        }

        return sourceData;
    };

    /**
     * Gets the render job for rendering an After Effects template for the render engine.
     * @param {object} layers
     * @param {object} interfaceSetup
     * @param {object} blockData
     * @param {string} language
     * @returns {object} - Job to send to the render engine.
     */
    static getAfterEffectsRenderJob(layers, interfaceSetup = [], blockData, language) {
        // Get the data from blockData to pass to the job.
        return Object.keys(layers).reduce((all, comp) => {
            layers[comp].forEach((layer) => {
                // Create a model to search for in the interface setup.
                const model = comp + '.' + layer.key;
                const inputs = this.getAdobeInterfaceSetupInputs(interfaceSetup, model);

                inputs.forEach((input) => {
                    const type = this.getInputType(input);
                    const value = this.getValueFromInputModel(input, blockData, language, type);

                    // Only add a new data object when the value is defined.
                    if (value !== undefined && value !== null) {
                        const param = {
                            comp,
                            layerName: layer.key,
                            type,
                            value
                        };

                        if (layer.id) param.id = layer.id;
                        all.push(param);

                        if (type === 'text' || type === 'image' || type === 'video' || type === 'audio' || type === 'source') {
                            // Find the same layer in other compositions and add a new value for it.
                            Object.keys(layers).forEach((composition) => {
                                // If it is the same composition, then there is already an value for it.
                                if (composition === comp) return;
                                const foundInput = layers[composition].find(
                                    (item) =>
                                        item.key === layer.key ||
                                        (item.originalName && layer.originalName && item.originalName === layer.originalName && item.type === layer.type)
                                );

                                if (!foundInput) return;

                                // Add a new value for the same layer in different compositions.
                                const param = {
                                    comp: composition,
                                    layerName: layer.key,
                                    type,
                                    value
                                };

                                if (foundInput.id) param.id = foundInput.id;
                                all.push(param);
                            });
                        }
                    }
                });
            });

            return all;
        }, []);
    }

    /**
     * Gets the render job for rendering an InDesign template for the render engine.
     * @param {object} layers
     * @param {object} interfaceSetup
     * @param {object} blockData
     * @param {string} language
     * @returns {object} - Job to send to the render engine.
     */
    static getInDesignRenderJob(layers, interfaceSetup = [], blockData, language) {
        // Get the data from blockData to pass to the job.
        return Object.keys(layers).reduce((all, currentPage) => {
            layers[currentPage].forEach((layer) => {
                // Create a model to search for in the interface setup.
                const model = layer.key;
                const inputs = this.getAdobeInterfaceSetupInputs(interfaceSetup, model);

                inputs.forEach((input) => {
                    const type = this.getInputType(input);
                    const value = this.getValueFromInputModel(input, blockData, language, type);

                    // Only add a new data object when the value is defined.
                    if (value !== undefined && value !== null) {
                        const layerId = Number(layer.key.split('_')[1]);

                        all.push({
                            id: layerId,
                            type: layer.type.split('_')[1],
                            property: type,
                            value
                        });

                        if (type === 'text' || type === 'image' || type === 'source') {
                            // Find the same layer in other compositions and add a new value for it.
                            Object.keys(layers).forEach((page) => {
                                // If it is the same composition, then there is already an value for it.
                                if (page === currentPage) return;
                                const foundInput = layers[page].find((item) => item.title === layer.title && item.type === layer.type);

                                if (!foundInput) return;

                                // Add a new value for the same layer in different compositions.
                                const layerId = Number(foundInput.key.split('_')[1]);

                                all.push({
                                    id: layerId,
                                    type: layer.type,
                                    property: type,
                                    value
                                });
                            });
                        }
                    }
                });
            });

            return all;
        }, []);
    }

    /**
     * Get inputs that match the model.
     * @param {object} interfaceSetup - Interface setup data.
     * @param {string} model - Model to search for in the interface setup.
     * @returns {array} - Inputs that match the model.
     */
    static getAdobeInterfaceSetupInputs(interfaceSetup, model) {
        const inputs = [];

        function getInputs(interfaceSetup) {
            interfaceSetup &&
                interfaceSetup.forEach((item) => {
                    if (item.model && item.model.includes(model)) {
                        inputs.push(item);
                    }

                    if (item.items && item.items.length) {
                        getInputs(item.items);
                    }
                });
        }

        getInputs(interfaceSetup);

        return inputs;
    }

    /**
     * Get the type of the input.
     * @param {object} input - Input object.
     * @returns {string} - Type of the input.
     */
    static getInputType(input) {
        const modelSplit = input.model.split('.');
        if (input.model.includes('[[language]]')) return modelSplit.at(-2);
        return modelSplit.at(-1);
    }

    /**
     * Get the value of the input.
     * @param {object} input - Input object.
     * @param {object} blockData - Block data.
     * @param {string} language - Language.
     * @param {string} type - Type of the input.
     * @returns {any} - Value of the input.
     */
    static getValueFromInputModel(input, blockData, language, type) {
        const value = (() => {
            let searchModel = input.model;
            if (input.model.includes('[[language]]')) {
                searchModel = input.model.replace('[[language]]', language);
            }

            if (input.type === 'textMultiLanguage') {
                searchModel += `.${language}`;
            }

            if (blockData.frames === undefined) {
                return get(blockData, searchModel);
            }

            /**
             * Where there are multiple frames, search in each frame if the value is there.
             * If it finds a value, use that and stop the loop.
             */
            let frameValue;

            for (const frame in blockData.frames) {
                const foundValue = get(blockData.frames[frame], searchModel);

                if (foundValue) {
                    frameValue = foundValue;
                    break;
                }
            }

            return frameValue;
        })();

        /**
         * Get the correct value based on the type.
         * For media and text layers, we want to check if they are multi language.
         */
        switch (type) {
            case 'image':
            case 'video':
            case 'audio':
            case 'source':
                if (value === undefined) return;
                return value.url;
            case 'misc':
                if (!value) return;
                if (value.length === 0) return;
                return value[0].url;
            case 'text':
                if (value === undefined) return;
                if (value[language] !== undefined && value[language].value !== undefined) {
                    return value[language].value;
                } else if (value['EN'] !== undefined && value['EN'] !== undefined) {
                    return value['EN'].value;
                } else if (value.value !== undefined) {
                    return value.value;
                }
                return value;
            case 'scale':
                if (value === undefined) return;
                return [value, value];
            case 'fontColor':
                if (value === undefined) return;
                // Remove alpha from the color.
                if (value.length > 7) {
                    return value.substring(0, value.length - 2);
                }

                return value;
            case 'visible':
                if (value === undefined) {
                    return false;
                }
                return value;
            default:
                return value;
        }
    }

    /**
     * Calculates the estimated template size.
     * @param {object} data - Data to calculate the estimated template size.
     * @returns {number} - Estimated total size of the template.
     */
    static calculatedEstimatedTemplateSize = (data) => {
        const { format, frames, overwrites } = data;
        const template = cloneDeep(data.template);

        const baseSize = 34000; // 34kb
        const averageCustomFontSize = 50000; // 75kb
        const brandGuideFonts = {};

        const size = Object.keys(template.frames).reduce((total, frame) => {
            const layers = template.layers[frame] || [];

            const checkSizeOfLayers = (layers) => {
                layers.forEach((layer) => {
                    // Skip audio, video and lottie layers, because there we don't know the size of these.
                    if (['audio', 'video', 'lottie'].includes(layer.type)) return;
                    const frameData = template.frames[frame] || {};
                    const layerProperties = frameData.layers[layer.key] || {};

                    const currentFrame = data && frames && frames[frame];
                    const userInput = (currentFrame && currentFrame[layer.key]) || {};

                    if (Array.isArray(layerProperties.style) && !layerProperties.style.length) {
                        layerProperties.style = {};
                    }

                    const layerOverwrites = (() => {
                        if (!overwrites) return;
                        return (
                            overwrites[format] && overwrites[format].frames && overwrites[format].frames[frame] && overwrites[format].frames[frame][layer.key]
                        );
                    })();

                    const formatProperties = (layerProperties.formats && layerProperties.formats[format]) || {};
                    const properties = merge(layerProperties.style || {}, formatProperties);
                    const styles = merge(properties, userInput, layerOverwrites);

                    if (layer.type === 'text') {
                        if (
                            styles.textStyling.normal &&
                            styles.textStyling.normal.fontSouce &&
                            styles.textStyling.normal.fontSouce.indexOf('brandGuide') > -1 &&
                            !brandGuideFonts[styles.textStyling.normal.fontFamily]
                        ) {
                            brandGuideFonts[styles.textStyling.normal.fontFamily] = true;
                            total += averageCustomFontSize;
                        }
                        if (
                            styles.textStyling.highlighted &&
                            styles.textStyling.highlighted.fontSouce &&
                            styles.textStyling.highlighted.fontSouce.indexOf('brandGuide') > -1 &&
                            !brandGuideFonts[styles.textStyling.highlighted.fontFamily]
                        ) {
                            brandGuideFonts[styles.textStyling.highlighted.fontFamily] = true;
                            total += averageCustomFontSize;
                        }
                    } else if (layer.type === 'image') {
                        // Image from user input.
                        if (styles.image && styles.image.size) {
                            total += styles.image.size;
                        }
                        // Image size from Template Designer.
                        else if (styles.imageFileSize) {
                            total += styles.imageFileSize;
                        }
                    } else {
                        // Image from user input.
                        if (styles.image && styles.image.size) {
                            total += styles.image.size;
                        }
                        // Background image size from Template Designer.
                        else if (styles.backgroundImageFileSize) {
                            total += styles.backgroundImageFileSize;
                        }
                    }

                    if (layer.children && layer.children.length > 0) {
                        checkSizeOfLayers(layer.children);
                    }
                });
            };

            checkSizeOfLayers(layers);

            return total;
        }, 0);

        const totalSize = size + baseSize;

        return totalSize;
    };
}
