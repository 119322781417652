/**
 * FormFlowHelpers class
 */
export default class FormFlowHelpers {
    /**
     * Format options to a format the FormFlow inputs can handle.
     * Various formats are supported:
     * - K/V Pairs as an object
     * - Array of objects with id/title fields
     * - Array of objects with id/name fields
     * - Array of objects with value/label fields
     */
    static standardizeOptions = (rawOptions) => {
        let options = [];
        if (!rawOptions) return options;
        if (!Array.isArray(rawOptions) && typeof rawOptions === 'object' && rawOptions !== null) {
            for (const i in rawOptions) {
                options.push({ value: i, label: rawOptions[i] });
            }
        } else if (rawOptions.length && rawOptions[0] && rawOptions[0].title) {
            options = rawOptions.map((item) => ({
                value: item.id,
                label: item.title,
                description: item.description,
                icon: item.icon,
                category: item.category ? item.category : false
            }));
        } else if (rawOptions.length && rawOptions[0] && rawOptions[0].name) {
            options = rawOptions.map((item) => ({
                value: item.id,
                label: item.name,
                category: item.category ? item.category : false
            }));
        } else {
            options = rawOptions;
        }
        return options;
    };
}
