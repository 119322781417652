import EditorData from 'components/editor-data/EditorData';
import CreativeBuilderSocialChannel from 'components/publishing/PublishProfileParser/helpers/creative-builder-social-channel';
import DataHelpers from '../DataHelpers';
import Setup from '../Setup';
import SnackbarUtils from '../../ui-base/SnackbarUtils';
import TemplateHelpers from '../TemplateHelpers';

export default class PublishHelpers {
    /**
     * Get Facebook age settings
     */
    static getFacebookAge() {
        let age = EditorData.getValueFromModel('settings.publish.facebook.age');
        if (!age) {
            age = EditorData.getValueFromModel('publish.facebook.age');
        }

        if (age && age.min && age.max) {
            return { min: parseInt(age.min), max: parseInt(age.max) };
        }
        return {};
    }

    /**
     * Get Ad Account id
     */
    static getFacebookAdAccountId() {
        let adAccountId = Setup.getValueFromModel('publish.facebook.adAccountId');
        const market = EditorData.get('market');
        const brand = EditorData.get('brand');

        // Country based
        const adAccountIdByCountry = Setup.getValueFromModel('publish.facebook.adAccountIdByCountry');
        if (adAccountIdByCountry && adAccountIdByCountry[market]) {
            adAccountId = adAccountIdByCountry[market];
        }

        // Brand based
        const adAccountIdByBrand = Setup.getValueFromModel('publish.facebook.adAccountIdByBrand');
        if (adAccountIdByBrand && adAccountIdByBrand[brand]) {
            adAccountId = adAccountIdByBrand[brand];
        }

        // Campaign based
        const campaignFacebookAdAccountId = EditorData.getValueFromModel('settings.publish.facebook.adAccountId');
        if (campaignFacebookAdAccountId) {
            adAccountId = campaignFacebookAdAccountId;
        }

        return adAccountId;
    }

    /**
     * Get Linkedin Account Id
     * This finds the page id in the setup or this campaign
     */
    static getLinkedInAccountId() {
        let linkedinAccountId = Setup.getValueFromModel('publish.linkedin.accountId');
        const market = EditorData.get('market');
        const brand = EditorData.get('brand');

        const linkedinAccountIdByCountry = Setup.getValueFromModel('publish.linkedin.accountIdByCountry');
        if (linkedinAccountIdByCountry && linkedinAccountIdByCountry[market]) {
            linkedinAccountId = linkedinAccountIdByCountry[market];
        }

        const linkedinAccountIdByBrand = Setup.getValueFromModel('publish.linkedin.accountIdByBrand');
        if (linkedinAccountIdByBrand && linkedinAccountIdByBrand[brand]) {
            linkedinAccountId = linkedinAccountIdByBrand[brand];
        }

        const campaignLinkedinAccountId = EditorData.getValueFromModel('settings.publish.linkedin.accountId');
        if (campaignLinkedinAccountId) {
            linkedinAccountId = campaignLinkedinAccountId;
        }

        return linkedinAccountId;
    }

    /**
     * Get Facebook Page Id
     * This finds the page id in the setup or this campaign
     */
    static getFacebookPageId() {
        let facebookPageId = Setup.getValueFromModel('publish.facebook.pageId');
        const market = EditorData.get('market');
        const brand = EditorData.get('brand');

        // Legacy support
        if (Setup.getValueFromModel('publish.facebook.facebookPageId')) {
            facebookPageId = Setup.getValueFromModel('publish.facebook.facebookPageId');
        }

        const facebookPageIdByCountry = Setup.getValueFromModel('publish.facebook.pageIdByCountry');
        if (facebookPageIdByCountry && facebookPageIdByCountry[market]) {
            facebookPageId = facebookPageIdByCountry[market];
        }

        const facebookPageIdByBrand = Setup.getValueFromModel('publish.facebook.pageIdByBrand');
        if (facebookPageIdByBrand && facebookPageIdByBrand[brand]) {
            facebookPageId = facebookPageIdByBrand[brand];
        }

        const campaignFacebookPageId = EditorData.getValueFromModel('settings.publish.facebook.pageId');
        if (campaignFacebookPageId) {
            facebookPageId = campaignFacebookPageId;
        }

        return facebookPageId;
    }

    /**
     * Get Facebook Page Id
     * This finds the page id in the setup or this campaign
     */
    static getFacebookPageIdPost() {
        const postPageId = Setup.getValueFromModel('publish.facebook.facebookPostPageId');
        if (postPageId) {
            return postPageId;
        }

        const pageId = PublishHelpers.getFacebookPageId();
        return pageId;
    }

    /**
     * Get asset type
     */
    static getAssetType(assetType) {
        if (assetType === 'dynamicVideo' || assetType === 'dynamicVideoDesigned' || assetType === 'dynamicAfterEffects') {
            return 'video';
        } else {
            return 'image';
        }
    }

    /**
     * Get Facebook Page Id
     */
    static getInstagramId() {
        let instagramId = Setup.getValueFromModel('publish.facebook.instagramId');
        const market = EditorData.get('market');
        const brand = EditorData.get('brand');

        const instagramIdByCountry = Setup.getValueFromModel('publish.facebook.instagramIdByCountry');
        if (instagramIdByCountry && instagramIdByCountry[market]) {
            instagramId = instagramIdByCountry[market];
        }

        const instagramIdByBrand = Setup.getValueFromModel('publish.facebook.instagramIdByBrand');
        if (instagramIdByBrand && instagramIdByBrand[brand]) {
            instagramId = instagramIdByBrand[brand];
        }

        const campaignInstagramId = EditorData.getValueFromModel('settings.publish.facebook.instagramId');
        if (campaignInstagramId) {
            instagramId = campaignInstagramId;
        }

        return instagramId;
    }

    /**
     * Get Active pixel id
     */
    static getFacebookPixelId() {
        let facebookPixelId = Setup.getValueFromModel('publish.facebook.pixelId');
        const campaignFacebookPixelId = EditorData.getValueFromModel('settings.publish.facebook.pixelId.id');
        if (campaignFacebookPixelId) {
            facebookPixelId = campaignFacebookPixelId;
        }

        return facebookPixelId;
    }

    /**
     * Get Campaign Manager Advertiser Id
     */
    static getDCMAdvertiserId() {
        let advertiserId = Setup.getValueFromModel('publish.dcm.advertiserId');

        const campaignAdvertiserId = EditorData.getValueFromModel('settings.publish.dcm.advertiserId');
        if (campaignAdvertiserId) {
            advertiserId = campaignAdvertiserId;
        }
        return advertiserId;
    }

    /**
     * Get Campaign Manager Site Id
     */
    static getDCMSiteId() {
        let siteId = Setup.getValueFromModel('publish.dcm.siteId');

        const campaignSiteId = EditorData.getValueFromModel('settings.publish.dcm.siteId');
        if (campaignSiteId) {
            siteId = campaignSiteId;
        }
        return siteId;
    }

    /**
     * Get FacebookGeoLocations
     */
    static getFacebookGeoLocations() {
        let geoLocations = Setup.getValueFromModel('publish.facebook.geoLocations');

        let campaignGeoLocations = EditorData.getValueFromModel('settings.publish.facebook.geoLocations');
        if (campaignGeoLocations) {
            geoLocations = campaignGeoLocations;
        }
        if (!campaignGeoLocations) {
            campaignGeoLocations = EditorData.getValueFromModel('settings.publish.facebook.geo_locations');
            if (campaignGeoLocations) {
                geoLocations = campaignGeoLocations;
            }
        }
        return geoLocations;
    }

    /**
     *  Get Facebook interests from campaigns
     * @returns
     */
    static getFacebookInterests() {
        const interests = EditorData.getValueFromModel('settings.publish.facebook.interests');

        if (!interests || !interests.length) {
            return [];
        }
        return interests.map((interest) => {
            return {
                id: interest.id,
                name: interest.name
            };
        });
    }

    /**
     * getFacebookDynamicAdAssetFeedSpec
     * Build a facebook asset feed spec for the assets
     * @param {*} type  The ad format. Can be AUTOMATIC_FORMAT, SINGLE_VIDEO, SINGLE_IMAGE
     * @param {*} assetType The asset type that is used. Is coming from the creativebuilder.type field
     * @param {*} asset_feed_spec The current asset feed spec that gets expanded
     * @param {*} format The format to use
     * @param {*} resourceName The resource name to be used
     */
    static getFacebookDynamicAdAssetFeedSpecBase({
        type = 'AUTOMATIC_FORMAT',
        asset_feed_spec,
        message,
        messages,
        link,
        links,
        caption,
        headline,
        headlines,
        carousels,
        cta,
        description,
        descriptions,
        bodies,
        titles,
        images,
        videos
    }) {
        asset_feed_spec = {
            images: [],
            videos: [],
            bodies: [],
            link_urls: [
                {
                    website_url: link,
                    display_url: caption
                }
            ],
            titles: [],
            ad_formats: [],
            call_to_action_types: [],
            descriptions: [],
            carousels: [],
            asset_customization_rules: []
        };

        if (carousels) {
            asset_feed_spec.carousels = carousels;
        }
        if (links) {
            asset_feed_spec.link_urls = links;
        }
        if (message) {
            asset_feed_spec.bodies.push({ text: message });
        }
        if (messages) {
            asset_feed_spec.bodies = messages;
        }
        if (bodies) {
            asset_feed_spec.bodies = bodies;
        }
        if (headline) {
            asset_feed_spec.titles.push({ text: headline });
        }
        if (headlines) {
            asset_feed_spec.titles = headlines;
        }
        if (titles) {
            asset_feed_spec.titles = titles;
        }
        if (description) {
            asset_feed_spec.descriptions.push({ text: description });
        }
        if (descriptions) {
            asset_feed_spec.descriptions = descriptions;
        }
        if (cta) {
            asset_feed_spec.call_to_action_types.push(cta);
        }
        if (images) {
            asset_feed_spec.images = images;
        }
        if (videos) {
            asset_feed_spec.videos = videos;
        }
        asset_feed_spec.ad_formats.push(type);

        return asset_feed_spec;
    }

    /**
     * Add utm to Facebook objects/links
     * This loops through the data object to replace urls
     * @param {*} item
     * @param {*} utm
     */
    static addUTMToFacebookLinks(url, item, utm) {
        // We have a static ad
        if (item.contents) {
            for (const i in item.contents) {
                if (!item.contents[i].link) {
                    item.contents[i].link = url;
                }
                item.contents[i].link = PublishHelpers.appendParameterToLink(item.contents[i].link, utm);
            }
        }

        // We have a dynamic ad
        if (item.asset_feed_spec) {
            if (item.asset_feed_spec.link_urls) {
                item.asset_feed_spec.link_urls.forEach((linkItem) => {
                    if (linkItem.website_url) {
                        linkItem.website_url = PublishHelpers.appendParameterToLink(linkItem.website_url, utm);
                    }
                });
            }
        }
    }

    /**
     * Convert Facebook campaign objectives to the right settings
     * @param {*} objective
     * @returns
     */
    static getFacebookObjectiveSettings(objective) {
        const adsetDataItem = {};

        // Depricated ones
        if (objective === 'CONVERSIONS') {
            adsetDataItem.optimization_goal = 'OFFSITE_CONVERSIONS';
            adsetDataItem.billing_event = 'IMPRESSIONS';

            // Select custom event
            const customEvent = EditorData.getValueFromModel('settings.publish.facebook.customEvent');
            if (customEvent) {
                adsetDataItem.promoted_object = {
                    pixel_id: customEvent.pixel.id,
                    custom_event_type: customEvent.custom_event_type,
                    pixel_rule: customEvent.rule
                };
            }
        }
        if (objective === 'LINK_CLICKS') {
            adsetDataItem.optimization_goal = 'LINK_CLICKS';
            adsetDataItem.billing_event = 'LINK_CLICKS';
        }
        if (objective === 'LEAD_GENERATION') {
            adsetDataItem.optimization_goal = 'LEAD_GENERATION';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'APP_INSTALLS') {
            adsetDataItem.optimization_goal = 'APP_INSTALLS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'REACH') {
            adsetDataItem.optimization_goal = 'REACH';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'POST_ENGAGEMENT') {
            adsetDataItem.optimization_goal = 'POST_ENGAGEMENT';
            adsetDataItem.billing_event = 'POST_ENGAGEMENT';
        }
        if (objective === 'PAGE_LIKES') {
            adsetDataItem.optimization_goal = 'PAGE_LIKES';
            adsetDataItem.billing_event = 'PAGE_LIKES';
        }
        if (objective === 'VIDEO_VIEWS') {
            adsetDataItem.optimization_goal = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'POST_ENGAGEMENT') {
            adsetDataItem.optimization_goal = 'POST_ENGAGEMENT';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        // New ones
        if (objective === 'OUTCOME_AWARENESS') {
            adsetDataItem.optimization_goal = 'REACH';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'OUTCOME_TRAFFIC') {
            adsetDataItem.optimization_goal = 'LINK_CLICKS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'OUTCOME_ENGAGEMENT') {
            adsetDataItem.optimization_goal = 'REACH';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'OUTCOME_APP_PROMOTION') {
            adsetDataItem.optimization_goal = 'APP_INSTALLS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'OUTCOME_SALES') {
            adsetDataItem.optimization_goal = 'OFFSITE_CONVERSIONS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }
        if (objective === 'OUTCOME_LEADS') {
            adsetDataItem.optimization_goal = 'OFFSITE_CONVERSIONS';
            adsetDataItem.billing_event = 'IMPRESSIONS';
        }

        return adsetDataItem;
    }

    /**
     * Get facebook dynamic asset resource name
     * @param {*} param0
     * @returns
     */
    static getFacebookDynamicAdAssetFeedSpecAssetsResource({ resourceName, assetType, source }) {
        let resourceNameItem = '{{' + resourceName + '}}';
        if (source === 'static') {
            resourceNameItem = CreativeBuilderSocialChannel.assetsDone[resourceName];
        } else if (source && source === 'socialPublish') {
            resourceNameItem = '{{' + resourceName + '_asset}}';
        } else if (!(assetType === 'dynamicVideo' || assetType === 'dynamicVideoDesigned' || assetType === 'dynamicAfterEffects')) {
            resourceNameItem = '{{' + resourceName + '-image}}';
        }
        return resourceNameItem;
    }

    /**
     * getFacebookDynamicAdAssetFeedSpec
     * Build a facebook asset feed spec for the assets
     * @param {*} type  The ad format. Can be AUTOMATIC_FORMAT, SINGLE_VIDEO, SINGLE_IMAGE
     * @param {*} assetType The asset type that is used. Is coming from the creativebuilder.type field
     * @param {*} asset_feed_spec The current asset feed spec that gets expanded
     * @param {*} format The format to use
     * @param {*} resourceName The resource name to be used
     */
    static getFacebookDynamicAdAssetFeedSpecAssets({ type = 'AUTOMATIC_FORMAT', assetType, asset_feed_spec, format, resourceName, targeting, source }) {
        // Set default targeting set
        let targetingSet = targeting;
        if (!targeting) {
            targetingSet = {
                facebook_positions: ['feed', 'marketplace', 'video_feeds', 'instant_article', 'search', 'story'],
                instagram_positions: ['stream', 'explore', 'explore_home', 'story'],
                messenger_positions: ['messenger_home', 'story']
            };
        }

        // Function to filter the placements
        const findPositions = function (allItems, targeting, type = 'facebook_positions') {
            const resultItems = allItems.filter((item) => {
                if (targeting[type] && targeting[type].includes(item)) {
                    return true;
                } else {
                    return false;
                }
            });
            if (resultItems.length === 0) {
                return undefined;
            }
            return resultItems;
        };

        // Resource
        const resourceNameItem = PublishHelpers.getFacebookDynamicAdAssetFeedSpecAssetsResource({ resourceName, assetType, source });

        // Add the assets
        if (assetType === 'dynamicVideo' || assetType === 'dynamicVideoDesigned' || assetType === 'dynamicAfterEffects' || assetType === 'video') {
            asset_feed_spec.videos.push({
                adlabels: [{ name: format.format }],
                video_url: resourceNameItem
            });
            asset_feed_spec.images = undefined;
        } else {
            asset_feed_spec.images.push({
                adlabels: [{ name: format.format }],
                url: resourceNameItem
            });
            asset_feed_spec.videos = undefined;
        }

        let rule = 0;

        // Get image
        if (type === 'SINGLE_IMAGE') {
            // Square
            if (TemplateHelpers.getFormatDimension(format) === '1x1' || TemplateHelpers.getFormatDimension(format) === '4x5') {
                rule = {
                    customization_spec: {},
                    priority: 3,
                    image_label: {
                        name: format.format
                    }
                };
            }
            if (TemplateHelpers.getFormatDimension(format) === '16x9') {
                rule = {
                    customization_spec: {
                        publisher_platforms: ['facebook'],
                        facebook_positions: findPositions(['search'], targetingSet, 'facebook_positions')
                    },
                    image_label: {
                        name: format.format
                    },
                    priority: 2
                };
            }

            if (TemplateHelpers.getFormatDimension(format) === '9x16') {
                rule = {
                    customization_spec: {
                        publisher_platforms: ['facebook', 'instagram', 'messenger'],
                        facebook_positions: findPositions(['story', 'facebook_reels'], targetingSet, 'facebook_positions'),
                        instagram_positions: findPositions(['story', 'reels'], targetingSet, 'instagram_positions'),
                        messenger_positions: findPositions(['story'], targetingSet, 'messenger_positions')
                    },
                    image_label: {
                        name: format.format
                    },
                    priority: 1
                };
            }
        }

        // Custom placements
        if (type === 'SINGLE_VIDEO') {
            // Square
            if (TemplateHelpers.getFormatDimension(format) === '1x1' || TemplateHelpers.getFormatDimension(format) === '4x5') {
                rule = {
                    customization_spec: {},
                    priority: 3,
                    video_label: {
                        name: format.format
                    }
                };
            }
            if (TemplateHelpers.getFormatDimension(format) === '9x16') {
                rule = {
                    customization_spec: {
                        publisher_platforms: ['facebook', 'instagram', 'messenger'],
                        facebook_positions: findPositions(['story', 'facebook_reels'], targetingSet, 'facebook_positions'),
                        instagram_positions: findPositions(['story', 'reels'], targetingSet, 'instagram_positions'),
                        messenger_positions: findPositions(['story'], targetingSet, 'messenger_positions')
                    },
                    priority: 1,
                    video_label: {
                        name: format.format
                    }
                };
            }
            if (TemplateHelpers.getFormatDimension(format) === '16x9') {
                rule = {
                    customization_spec: {
                        publisher_platforms: ['facebook'],
                        facebook_positions: findPositions(['search'], targetingSet, 'facebook_positions')
                    },
                    priority: 2,
                    video_label: {
                        name: format.format
                    }
                };
            }
        }

        if (rule) {
            if (asset_feed_spec.titles.length > 1) {
                rule.title_label = {
                    name: 'title'
                };
            }
            if (asset_feed_spec.bodies.length > 1) {
                rule.body_label = {
                    name: 'body'
                };
            }

            asset_feed_spec.asset_customization_rules.push(rule);
        }

        return asset_feed_spec;
    }

    /**
     * Creates the name used for the filename or the resource
     * This keeps a fixed structure for all the resources and filenames
     * @param {*} data
     */
    static creativeBuilderNaming({
        type,
        nameType,
        uuid,
        subsetItem,
        format,
        formatData,
        language,
        prefix,
        frame,
        setNr,
        subsetItemNr,
        title,
        customNameTemplate,
        data
    }) {
        let name = '';

        // Set in filename
        if (nameType === 'filename' && customNameTemplate) {
            name = EditorData.getDynamicValue(customNameTemplate, { uuid, format, formatData, language, frame, setNr, subsetItemNr, title, data });
            return name;
        }

        if (prefix) {
            name = name + prefix + '-';
        }
        if (type) {
            name = name + type + '-';
        }

        if (nameType === 'filename' && setNr) {
            name = name + setNr + '-';
        } else if (nameType === 'filename') {
            name = name + uuid.substring(0, 10) + '-';
            name = name.replace(':', '-');
        } else {
            name = name + uuid + '-';
        }

        // Convert title to a string if it a number
        if (title !== undefined && typeof title === 'number') {
            title = title.toString();
        }

        // Add title to filename
        if (nameType === 'filename' && title) {
            const cleanedTitle = title.replace(/[^\w\s.-]/g, '');
            name = name + cleanedTitle + '-';
        }

        // Subset item
        if (subsetItem) {
            if (nameType === 'filename' && subsetItemNr) {
                name = name + subsetItemNr + '-';
            } else if (nameType === 'filename') {
                name = name + subsetItem.substring(0, 10) + '-';
            } else {
                name = name + subsetItem + '-';
            }
        }

        // Add format details in name
        if (formatData && nameType === 'filename' && format != formatData.width + 'x' + formatData.height) {
            name = name + format + '-' + formatData.width + 'x' + formatData.height + '-';
        } else if (format) {
            name = name + format + '-';
        }

        // Add language
        if (language) {
            name = name + language + '-';
        }

        // Add frame
        if (frame) {
            name = name + 'frame' + frame + '-';
        }

        name = name.substring(0, name.length - 1);
        return name;
    }

    /**
     * Append parameter to link
     * This appends the parameter to the current url
     * @param {*} link
     * @param {*} parameters
     */
    static appendParameterToLink(link, parameters = '') {
        if (!link || !link.substring) {
            SnackbarUtils.error('The link was not set correctly. ');
            return '';
        }

        if (link.substring(0, 3) === 'www') {
            link = 'https://' + link;
        }

        if (link.includes('?')) {
            if (parameters && parameters.substring(0, 1) === '?') {
                return link + '&' + parameters.substring(1);
            }
            if (parameters && parameters.substring(0, 1) === '&') {
                return link + parameters;
            }
            return link + '&' + parameters;
        } else if (parameters.includes('?') && parameters.substring(0, 1) === '?') {
            return link + parameters;
        } else {
            return link + '?' + parameters;
        }
    }

    /**
     * Correct link without https
     * @param {*} link
     */
    static linkFormat(link) {
        if (link.substring(0, 3) === 'www') {
            return 'https://' + link;
        }
        return link;
    }

    /**
     * Get value of a fields
     * Gets a value and returns the string. This checks for the existance of language and value objects.
     * @param {*} item The field from the interface. This is for instance a textfield object
     * @param {*} language The current language
     * @param {*} dataSet The original dataset
     */
    static getDynamicFieldValue(item, language, dataSet) {
        let model = item.model;
        if (!item.model) {
            model = item;
        }
        let value;

        // Find a value
        if (DataHelpers.getValue(dataSet, model + '.' + language + '.value')) {
            value = DataHelpers.getValue(dataSet, model + '.' + language + '.value');
        } else if (DataHelpers.getValue(dataSet, model + '.value')) {
            value = DataHelpers.getValue(dataSet, model + '.value');
        } else if (DataHelpers.getValue(dataSet, model + '.url')) {
            value = DataHelpers.getValue(dataSet, model + '.url');
        } else if (DataHelpers.getValue(dataSet, model)) {
            value = DataHelpers.getValue(dataSet, model);
        }

        // Return the values
        if (Object.prototype.toString.call(value) === '[object String]') {
            return value;
        } else if (Array.isArray(value)) {
            return value.join(', ');
        }
        return undefined;
    }

    /**
     * Get Ad Account id from Google
     */
    static getGoogleAdsAccountId() {
        let adAccountId = Setup.getValueFromModel('publish.googleAds.adAccountId');
        const market = EditorData.get('market');

        // Country based
        const adAccountIdByCountry = Setup.getValueFromModel('publish.googleAds.adAccountIdByCountry');
        if (adAccountIdByCountry && adAccountIdByCountry[market]) {
            adAccountId = adAccountIdByCountry[market];
        }

        // Campaign based
        const googleAdsAdAccountId = EditorData.getValueFromModel('settings.publish.googleAds.adAccountId');
        if (googleAdsAdAccountId) {
            adAccountId = googleAdsAdAccountId;
        }

        return adAccountId;
    }
}
