import i18n from './i18n';
import isoLanguages from './data/iso-languages.json';

export default class Translation {
    /**
     * Translate copy using i18next.
     * See docs: https://react.i18next.com/ and https://www.i18next.com/
     * @returns translated string.
     */
    static get(model: string, namespace = 'common', options?: { [key: string]: string | number }) {
        return i18n.t<string>(model, { ...options, ns: namespace });
    }

    /**
     * Return the translation for the current active language when given an object with translations.
     * There is a fallback in case a string is passed
     * @param {object|string} translations
     * @returns translated string.
     */
    static inline(translations) {
        // Find the translation for the current language in an object.
        const translateObject = (value) => {
            const lang = i18n.language;
            if (value[lang]) {
                return value[lang];
            } else if (value.en) {
                return value.en;
            } else return JSON.stringify(value);
        };

        if (typeof translations === 'object') {
            return translateObject(translations);
        } else if (typeof translations === 'string') {
            try {
                const transObject = JSON.parse(translations);
                return translateObject(transObject);
            } catch (error) {
                return translations;
            }
        } else {
            console.error('Translation failed for', translations);
            return '';
        }
    }

    /**
     * Change the currently used interface language.
     * @param {string} lang
     */
    static changeLanguage(lang) {
        i18n.changeLanguage(lang);

        localStorage.setItem('language-campaigndesigner', lang);
        window.location.reload();
    }

    /**
     * Get current available languages.
     */
    static getLanguages() {
        return Object.keys(i18n.options.resources ? i18n.options.resources : {});
    }

    /**
     * Get current available languages.
     */
    static getLanguageLabel(lng) {
        return isoLanguages[lng.toUpperCase()];
    }

    /**
     * Get current available languages.
     */
    static getCurrentLanguage() {
        return i18n.language;
    }
}
