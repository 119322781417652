import store from '../../../store';
import Setup from '../Setup';
import Request from '../Request';

export default class User {
    /**
     * Get user object or individual field
     * @param {String} field Field to be requested (optional)
     * @return {Object} requested user data
     */
    static get(field = false) {
        if (field) {
            return store.getState().auth[field];
        } else {
            return store.getState().auth;
        }
    }

    /**
     * Set user data
     * @param {String} field Field
     * @param {String} value Value
     */
    static set(field, value) {
        store.dispatch({
            type: 'AUTH_USER_PROFILE_SET',
            payload: {
                [field]: value
            }
        });
    }

    /**
     * Get user setup details
     * @return {Object} current user setup details
     */
    static getUserType() {
        const setup = store.getState().resources.setup;
        const userTypes = setup.userManagement.types;
        const auth = store.getState().auth;

        return userTypes[auth.type];
    }

    /**
     * Get the brands a user has rights to
     * @returns {object} brands
     */
    static getBrands() {
        const sourceBrands = Setup.get('brands');

        // If a user has multi rights, return all brands in the environment.
        if (this.hasRight('multiBrand')) return sourceBrands;

        // If a user has specific rights. Return those.
        const filteredBrands = {};
        const userBrands = this.get('brands') || [];
        userBrands.forEach((b) => {
            filteredBrands[b] = sourceBrands[b] || b;
        });
        return filteredBrands;
    }

    /**
     * Get the markets a user has rights to
     * @returns {object} markets
     */
    static getMarkets() {
        const sourceMarkets = Setup.get('markets');

        // If a user has multi rights, return all brands in the environment.
        if (this.hasRight('multiMarket')) return sourceMarkets;

        // If a user has specific rights. Return those.
        const filteredMarkets = {};
        const userMarkets = this.get('markets') || [];
        userMarkets.forEach((b) => {
            filteredMarkets[b] = sourceMarkets[b] || b;
        });
        return filteredMarkets;
    }

    /**
     * Get the departments a user has rights to
     * @returns {object} departments
     */
    static getDepartments() {
        const sourceDepartments = Setup.get('departments');

        // If a user has multi rights, return all brands in the environment.
        if (this.hasRight('multiDepartment')) return sourceDepartments;

        // If a user has specific rights. Return those.
        const filteredDepartments = {};
        const userDepartments = this.get('departments') || [];
        userDepartments.forEach((b) => {
            filteredDepartments[b] = sourceDepartments[b] || b;
        });
        return filteredDepartments;
    }

    /**
     * Check whether a user has a specific type
     * @param {string} type User type to look for
     * @return {boolean} Whether user has right
     */
    static hasType(type) {
        try {
            const user = store.getState().auth;

            if (Array.isArray(type) && type.includes(user.type)) {
                return true;
            } else if (user.type === type) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    /**
     * Checks whether the user has a specific user right
     * @param {string | string[]} right User right to look for
     * @return {boolean} Whether user has right
     */
    static hasRight(right) {
        let rights = [];
        if (!Array.isArray(right)) {
            rights.push(right);
        } else {
            rights = right;
        }

        try {
            const user = store.getState().auth;
            for (const i in rights) {
                if (user.rights.includes(rights[i])) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    /**
     * Get level
     * @return {integer} current level of user
     */
    static getLevel() {
        const auth = store.getState().auth;
        return auth.level;
    }

    /**
     * Checks whether the user has a specific access level
     * @param {int} level Integer of the level
     * @return {boolean} User has access to this level
     */
    static hasLevel(level = 0) {
        const auth = store.getState().auth;
        return auth.level >= level;
    }

    /**
     * Save profile name and profilePicture
     * @param {string} name
     * @param {sring} profilePicture url to pic
     * @return {object} Response of the call
     */
    static async saveUserProfile(newUserData) {
        const data = await Request.post('/me/editProfile', newUserData);

        if (data.success === 1) {
            store.dispatch({
                type: 'AUTH_USER_PROFILE_SUCCESS',
                payload: newUserData
            });
        } else {
            store.dispatch({
                type: 'AUTH_USER_PROFILE_ERROR',
                payload: {}
            });
        }
        return data;
    }
}
