import React from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Templates from '.';

/**
 * Loads templates and shows a loader in the meantime before openeing the component
 * @param {*} WrappedComponent
 * @param {array} templateTypes Load templates of these types
 * @param {boolean} hideSpinner Hide the default spinner
 * @param {boolean} getAllTemplates Also retrieve the inactive templates. This is needed for the Template Designer preview
 */
export default function withTemplates(WrappedComponent, templateTypes, full = false) {
    class TemplateWrapperHolder extends React.Component {
        constructor(props) {
            super(props);
            this.state = { loading: true };
        }

        componentDidMount() {
            this.loadTemplates();
        }

        loadTemplates = async () => {
            await Templates.asyncGetTemplatesList(templateTypes, full);
            this.setState({ loading: false });
        };

        render() {
            const { loading } = this.state;
            if (loading > 0) {
                return (
                    <div style={{ justifyContent: 'center', display: 'flex', width: '100%', padding: 16 }}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return <WrappedComponent {...this.props} />;
            }
        }
    }
    return TemplateWrapperHolder;
}
