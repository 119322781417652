import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import resources from './resources';

const urlLanguage = new URLSearchParams(window.location.search).get('lng');
const storageLanguage = localStorage.getItem('language-campaigndesigner');
const language = storageLanguage || urlLanguage || 'en';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: language,
        resources,
        debug: process.env.ENV ? ['development', 'acceptance'].includes(process.env.ENV) : false,
        ns: [
            'common',
            'dashboard',
            'settings',
            'campaigns',
            'concepts',
            'editor',
            'feed-management',
            'content-space',
            'template-designer',
            'ui-base',
            'ui-components',
            'routes',
            'analytics',
            'audio-library',
            'bricks',
            'sidekick'
        ],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss,
        }
    });

export default i18n;
