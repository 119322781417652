import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Store from 'types/store.type';
import resourceActions from 'reducers/Resources/actions';
import store from '../../../../store';

/**
 * useResources
 * A hook that loads resources and returns the loading state and the resources.
 * @param resourcesInput - The resources to load.
 * @returns The loading state and the resources.
 */
const useResources = <ResourceResponse>(resourcesInput: string[]): { loading: boolean; resources: ResourceResponse } => {
    const resourcesState = useSelector((state: Store) => state.resources);

    /**
     * Check resource input.
     */
    useEffect(() => {
        // If no resources are provided, do nothing.
        if (!resourcesInput || !Array.isArray(resourcesInput) || !resourcesInput.length) return;

        // Load the resources.
        resourcesInput.forEach((resource) => store.dispatch(resourceActions.loadResource({ resource })));
    }, [resourcesInput, store.dispatch]);

    /**
     * Check if all resources are loaded.
     */
    const loading = useMemo(() => {
        return !resourcesInput.every((resource) => resourcesState[resource] && !resourcesState[resource].loading);
    }, [resourcesState]);

    /**
     * Get the resources.
     */
    const resources = useMemo(() => {
        return resourcesInput.reduce((all, resource) => {
            all[resource] = resourcesState[resource] ?? null;
            return all;
        }, {} as ResourceResponse);
    }, [resourcesState]);

    return { loading, resources };
};

export { useResources };
