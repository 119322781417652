import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import resourceActions from 'reducers/Resources/actions';

/**
 * HOC
 * This wraps the component and passes the resource data of the requested resources
 * @param {*} WrappedComponent
 * @param {*} resourcesInput
 */
export default function withResources(WrappedComponent, resourcesInput) {
    class ResourceWrapperHolder extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                resourcesToLoad: []
            };

            // Start loading resources
            if (resourcesInput && resourcesInput.map) {
                resourcesInput.forEach((resource) => {
                    if (typeof resource === 'string') {
                        resource = { resource: resource };
                    }
                    this.state.resourcesToLoad.push(resource);
                    this.props.loadResource(resource);
                });
            }
        }

        /**
         * Map state to props
         * Use destinations and origins in view
         */
        static mapStateToProps(state) {
            const results = {
                resources: state.resources,
                location: state.router.location
            };

            return results;
        }

        render() {
            const { resources } = this.props;
            const { resourcesToLoad } = this.state;

            // Check current loading resources
            const resourceResults = {};
            let loading = resourcesToLoad.length;
            resourcesToLoad.forEach((item) => {
                if (item.resource in resources) {
                    resourceResults[item.resource] = resources[item.resource];
                    loading--;
                }
            });

            if (loading > 0) {
                return (
                    <div style={{ justifyContent: 'center', display: 'flex', width: '100%', padding: 16 }}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return <WrappedComponent {...resourceResults} {...this.props} />;
            }
        }
    }
    return connect(ResourceWrapperHolder.mapStateToProps, { ...resourceActions })(ResourceWrapperHolder);
}
