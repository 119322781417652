import Language from 'types/language.type';
// Imports for English
import en from 'locales/en/index.js';
import es from 'locales/es/index.js';
import nl from 'locales/nl/index.js';
import pt from 'locales/pt/index.js';
import zh from 'locales/zh/index.js';

type TranslationObject = {
    [key: string]: string | string[] | TranslationObject;
};

// Build the translation object.
const translationObject: Record<Language, TranslationObject> = {
    en,
    es,
    nl,
    pt,
    zh
};

export default translationObject;
