import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import resourceActions from 'reducers/Resources/actions';

/**
 * ResourceLoader
 * This components wraps a component, and shows it as soon as the reource was loaded
 */
class ResourceWrapperHolder extends React.Component {

    constructor(props) {
        super(props);

        // Start loading resources
        if (props.resourcesToLoad) {
            props.resourcesToLoad.forEach((resource) => {
                this.props.loadResource(resource);
            });
        }
    }

    /**
     * Map state to props
     * Use destinations and origins in view
     */
    static mapStateToProps(state) {

        let results = {
            resources: state.resources
        };

        return results;

    }

    render() {
        const { resources, data, resourcesToLoad, additionalProps, onMutation, WrappedComponent } = this.props;

        let resourceResults = {};
        let loading = resourcesToLoad.length;
        resourcesToLoad.forEach((resource) => {
            if (resources[resource]) {
                resourceResults[resource] = resources[resource];
                loading--;
            }
        });

        if (loading > 0) {
            return (<div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}><CircularProgress /></div>);
        }
        else {
            return <WrappedComponent {...resourceResults} {...data} {...additionalProps} onMutation={onMutation} />;
        }
    }
}
export default connect(ResourceWrapperHolder.mapStateToProps, { ...resourceActions })(ResourceWrapperHolder);
