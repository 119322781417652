import LWFiles from '../Files';

/**
 * LWFiles class
 * Used as extendable class for UploadApi and UploadCloud
 */
export default class LWUpload {
    file = null;
    fileName = null;
    data = {};
    settings = {};
    payload = {};
    callbacks = {
        start: null,
        uploadStart: null,
        uploadProgress: null,
        uploadSuccess: null,
        uploadError: null,
        processStart: null,
        processProgress: null,
        processEnd: null,
        error: null
    };

    constructor(props) {
        const { file, path, _key, fileName, supportedTypes = { image: 'img', video: 'vid', audio: 'aud' }, pollInterval = 2000, fileIsString = false } = props;
        this.file = file;
        this.fileName = fileName;
        this.fileIsString = fileIsString;
        this.path = path;
        this.data = LWFiles.getFileData(file, _key, fileIsString ? fileName : file.name);
        this.data.id = new Date().getTime() + Math.round(Math.random() * 1000);
        this.settings = {
            supportedTypes,
            pollInterval
        };
    }

    /**
     * @param action
     * @param callback
     */
    on(action, callback) {
        if (this.callbacks[action] === undefined) {
            console.log(`Action "${action}" not available in UploadApi`);
            return false;
        }
        this.callbacks[action] = callback;
    }

    /**
     * @param action
     * @param data
     */
    broadcast(action, data) {
        if (this.callbacks[action] === undefined) {
            return false;
        }

        if (this.callbacks[action] === null) {
            return false;
        }
        data.status = action;
        this.callbacks[action](data);
    }
}
