import axios from 'axios';
import EditorData from 'components/editor-data/EditorData';
import store from '../../../store';

/**
 * Setup class
 * This is a helper to get data from the customer setup.
 */
export default class Setup {
    /**
     * Get data from the setup object
     * @param {string} variable Subobject to fetch, optional
     */
    static get(variable = undefined) {
        // Get a single subobject
        if (variable) {
            try {
                return store.getState().resources.setup[variable];
            } catch (e) {
                return undefined;
            }
        }
        // Get full setup object
        else {
            return store.getState().resources.setup;
        }
    }

    /**
     * Get value from model
     * @param {string} model The model to check
     */
    static getValueFromModel(model = undefined) {
        // Get a single subobject
        if (model) {
            try {
                return EditorData.getValueFromModel(model, store.getState().resources.setup);
            } catch (e) {
                return undefined;
            }
        }
        // Get full setup object
        else {
            return store.getState().resources.setup;
        }
    }

    /**
     * Check whether the account has a certain module activated
     */
    static hasModule(module) {
        let modules = [];
        if (!Array.isArray(module)) {
            modules.push(module);
        } else {
            modules = module;
        }

        try {
            const setup = store.getState().resources.setup;
            for (const i in modules) {
                if (setup.modules.includes(modules[i])) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    /**
     * Get domain name
     */
    static getDomain() {
        return store.getState().setup.domain;
    }

    /**
     * Get domain data from server
     * @param {*} domain
     */
    static getDomainData(domain) {
        axios.post(`${process.env.APP_API_HOST}setup/load`, { domain }, { headers: { 'Content-Type': 'text/plain' }, withCredentials: true }).then((data) => {
            let dataSet = {};
            if (data.data.data) {
                dataSet = data.data.data;
            }

            if (!dataSet.accountId) {
                store.dispatch({
                    type: 'SETUP_FETCH_ERROR',
                    payload: {
                        domain: domain
                    }
                });
            } else {
                store.dispatch({
                    type: 'SETUP_FETCH_COMPLETE',
                    payload: {
                        domain: domain,
                        accountId: dataSet.accountId,
                        interfaceSetup: dataSet.interfaceSetup,
                        oAuthLogin: dataSet.oAuthLogin
                    }
                });
            }
        });
    }
}
